import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/SafeAndTerms/Services.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('../views/SafeAndTerms/PrivacyPolicy.vue')
  },
  {
    path: '/menu-page',
    name: 'menu-page',
    component: () => import('../views/MenuView.vue')
  },
  {
    path: '/app/download',
    name: 'down-load',
    component: () => import('../views/DownloadView.vue')
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
