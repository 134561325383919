<template>
  <top-memu></top-memu>
  <div :style="{ height: isMobile ? '0.88rem' : '100px' }"></div>
  <router-view />
  <p class="footer_txt" v-show="!isMobile">
    <span>©️2022 TOMORO COFFEE. All rights reserved. Contact us hitomoro@tomoro-coffee.com</span>
  </p>
  <p class="footer_txt_mobile" v-show="isMobile">
    <span>©️2022 TOMORO COFFEE. All rights reserved.</span>
    <span>Contact us hitomoro@tomoro-coffee.com</span>
  </p>
</template>
<script>
import topMemu from './components/memu-top/index'
import { isMobile } from './assets/until/mobile.js'
export default {
  components: {
    topMemu
  },
  data() {
    return {
      isMobile: false
    }
  },
  created() {
    this.isMobile = isMobile()
  },
}
</script>
<style lang="less">
@import url('./assets/font/font.css');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: auto;
  width: 100vw;
  overflow-x: auto;
  -webkit-text-size-adjust: 100%;
}



.footer_txt {
  height: 70px;
  width: 100%;
  min-width: 1440px;
  background: #F8F8F8;
  text-align: center;
  line-height: 70px;
  vertical-align: middle;
  white-space: nowrap;
  margin-bottom: 0;

  span {
    font-size: 16px;
    font-family: Barlow-Regular;
    color: #222222;
    line-height: 26px;
    font-weight: normal;
  }
}

.footer_txt_mobile {
  font-size: 0.2rem;
  font-family: Barlow-Regular;
  color: #222222;
  height: 1.08rem;
  margin-top: 0.68rem;
  background: #F8F8F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  margin: 0 auto;

  span {
    line-height: 0.3rem;
  }

}

@media screen and (min-height: 1024px) {
  .footer_txt {
    margin-top: calc(100vh - 1024px);
  }
}
</style>
