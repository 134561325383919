<template>
    <div class="menu_main">
        <div class="memu" v-if="!isMobile">
            <div class="line">
                <div class="memu_item icon"></div>
                <div class="memu_item home" :class="{ 'active': isActive === 0 }">
                    <span @click="tabClick('/home', 0)">Home</span>
                </div>
                <!-- <div class="memu_item menu" :class="{ 'active': isActive === 1 }">
                    <span @click="tabClick('/menu-page', 1)">Menu</span>
                </div> -->
                <div class="memu_item about" :class="{ 'active': isActive === 2 }">
                    <span @click="tabClick('/about', 2)">About</span>
                </div>
                <div class="memu_item download" :class="{ 'active': isActive === 3 }">
                    <span @click="tabClick('/app/download', 3)">Download App</span>
                </div>
                <div class="memu_item pull_tab" :class="{ 'active': isActive > 3 }">
                    <span>Terms and Conditons</span>
                    <ul>
                        <li @click="isActive = 4, tabClick('/services', 4)" :class="{ 'activeTxt': isActive === 4 }">
                            <div>Terms of Services</div>
                        </li>
                        <li @click="isActive = 5, tabClick('/policy', 5)" :class="{ 'activeTxt': isActive === 5 }">
                            <div>
                                Privacy Policy
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="memu_item lang">
                    <span>English</span>
                </div>
            </div>
        </div>
        <div class="memu_h5" v-if="isMobile">
            <div class="line">
                <div class="lang">
                    <span>English</span>
                </div>
                <div class="icon">
                    <div class="img_title"></div>
                </div>
                <div class="memu_icon" @click="closeAndOpen(true)">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        <div class="menu_toast" v-if="status">
            <div class="tab_line">
                <div class="img" @click="closeAndOpen(false)"></div>
                <ul class="menu_list">
                    <li v-for='(item, index) in tabData' :key="index" @click="tabClick(item.path)">
                        <span>{{ item.name }}</span>
                        <div class="icon" v-if="item.name === 'Terms and Conditons'" :class="{ 'open': childrenMenu }">
                        </div>
                    </li>
                </ul>
                <ul class="children" v-if="childrenMenu">
                    <li @click="tabClick('/services'), status = false">
                        <span>
                            Terms of Services
                        </span>
                    </li>
                    <li @click="tabClick('/policy'), status = false">
                        <span>
                            Privacy Policy
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { isMobile } from '../../assets/until/mobile.js'
export default {
    data() {
        return {
            isMobile: false,
            tabData: [{ name: 'Home', path: '/home' },
            // { name: 'Menu', path: '/menu-page' },
            { name: 'About', path: '/about' },
            { name: 'Download App', path: '/app/download' },
            { name: 'Terms and Conditons', path: '' }],
            status: false,
            isActive: 0,
            childrenMenu: false
        }
    },
    created() {
        this.isMobile = isMobile()
        switch (this.$route.path) {
            case '/home':
                this.isActive = 0
                break;
            case '/menu-page':
                this.isActive = 1
                break;
            case '/about':
                this.isActive = 2
                break;
            case '/app/download':
                this.isActive = 3
                break;
            case '/services':
                this.isActive = 4
                break;
            case 'policy':
                this.isActive = 5
                break;
            default:
                this.isActive = 0
                break;
        }

    },
    methods: {
        tabClick(path, index) {
            this.isActive = index
            if (path) {
                this.$router.push(path)
                this.status = false
            } else {
                this.childrenMenu = !this.childrenMenu
            }
        },
        closeAndOpen() {
            this.status = !this.status
        }
    }
}

</script>

<style lang="less" scoped>
@import url('./index.less');
</style>