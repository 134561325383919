<template>
  <div class="wraper">
    <div :class="[isMobile ? 'home_h5' : 'home']">
      <div class="txt_body">
        <div class="icon"></div>
        <h3>HEY !</h3>
        <h3>ENJOY YOUR COFFEE TIME</h3>
        <p>100% ARABICA COFFEE</p>
        <p>FRESHLY ROASTED & BREWED</p>
        <h3 v-if="!isMobile">@TOMORO</h3>
      </div>
      <div class="img_body">
        <h3 v-if="isMobile">@TOMORO</h3>
      </div>
    </div>
  </div>
</template>
<script>
import { isMobile } from '../assets/until/mobile.js'
export default {
  data() {
    return {
      isMobile: false
    }
  },
  created() {
    this.isMobile = isMobile()

  },
}
</script>

<style lang="less" scoped>
@import url("../assets/less/home/index.less");
</style>
