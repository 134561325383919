import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { Menu } from "ant-design-vue";
import 'ant-design-vue/lib/button/style/css'
import { getQueryString } from '@/assets/until/url.js'

const sensors = require('sa-sdk-javascript');
sensors.init({
	server_url: process.env.VUE_APP_SENSORS,  // default || production
	show_log: false,
	is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
	use_client_time: true,
	send_type: 'ajax',
	heatmap: {
			track_attr: ['invite_referral_code_copy', 'invite_record', 'invite_share', 'invite_land_referral_code_copy', 'invite_land_register_now'],
			//是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
			clickmap: 'default',
			//是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
			scroll_notice_map: 'not_collect',
			use_client_time: true,
			is_track_single_page: true,
			collect_tags:{
				div : true
			}
	},
});

const registerData = {}
const sourceId = getQueryString('sourceId')
if (sourceId) {
	registerData.source_id = sourceId
}
sensors.registerPage(registerData);
sensors.quick('autoTrack')

const app = createApp(App)
app.config.globalProperties.$sensors = sensors
app.use(router).use(Menu).mount('#app')
